<template>
  <CRow>
    <CCol>
      {{ data.name }}
      <a href="javascript:" @click="showProfile()">
        <i class="fas fa-eye"></i>
      </a>
    </CCol>
    <CModal :title="heading" size="lg" :show.sync="largeModal" :no-close-on-backdrop="true" color="primary">
      <CCardBody>
        <CCol md="12" class="align-self-center">
          <CRow>
            <CCol sm="6" md="4" lg="4" v-show="data.companyName">
              <p class="custome-labal">{{ $lang.branch.form.company_name }}</p>
              <p class="custome-detail">{{ data.companyName }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="data.name">
              <p class="custome-labal">{{ $lang.branch.form.branch_name }}</p>
              <p class="custome-detail">{{ data.name }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="data.location">
              <p class="custome-labal">{{ $lang.branch.form.address }}</p>
              <p class="custome-detail">{{ data.location }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="data.country">
              <p class="custome-labal">{{ $lang.branch.form.country }}</p>
              <p class="custome-detail">{{ data.country }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="data.state">
              <p class="custome-labal">{{ $lang.branch.form.state }}</p>
              <p class="custome-detail">{{ data.state }}</p>
            </CCol>
            <CCol sm="6" md="4" lg="4" v-show="data.city">
              <p class="custome-labal">{{ $lang.branch.form.city }}</p>
              <p class="custome-detail">{{ data.city }}</p>
            </CCol>
          </CRow>
        </CCol>
        <template hide-footer #footer style="display: none">
          <CButton @click="darkModal = false" color="danger" style="display: none">Discard</CButton>
          <CButton @click="darkModal = false" color="success" style="display: none">Accept</CButton>
        </template>
      </CCardBody>
    </CModal>
  </CRow>
</template>
<script>
import {profile, skills} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "BranchComponent",
  mixins: [Mixin],
  components: {},
  props: ["data"],
  data() {
    return {
      heading: "Branch Detail",
      largeModal: false,
      self: this,
      module: profile,
      moduleSkills: skills,
      profileImage: "",
      avatar: "",
    };
  },
  mounted() {
  },
  methods: {
    showProfile() {
      let self = this;
      self.largeModal = true;
    },
  },
};
</script>
